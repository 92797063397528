<template>
  <!-- 仅封装弹窗对象的创建、释放、打开与关闭 -->
  <div>
    <slot></slot>
  </div>
</template>

<script>
const getParent = ($component) => ($component.abstract || $component.$el === $component.$children[0].$el ? getParent($component.$parent) : $component)

export default {
  name: 'MapInfoWindow',
  props: {
    title: {
      type: String,
      default: () => {}
    },
    width: {
      type: Number,
      default: () => {}
    },
    // 对象信息
    objectInfo: {
      type: Object,
      default: () => {}
    },
    // 弹窗显示组件
    infoWindowComponent: {
      type: Object,
      default: () => {}
    },
    // 是否显示弹窗
    show: {
      type: Boolean,
      default: () => {}
    }
  },
  data() {
    return {
      lastPoint: {}
    }
  },
  watch: {
    width(val) {
      this.originInstance.setWidth(val)
    },

    title(val) {
      this.originInstance.setTitle(val)
    },
    show(val) {
      // 显示弹窗
      this.openInfoWindow()
    }
  },
  mounted() {
    const $parent = getParent(this.$parent)
    const map = $parent.map
    const { ready } = this
    map ? ready() : $parent.$on('ready', ready)
  },
  destroyed() {
    this.unload()
  },
  beforeDestroy() {
    this.unload()
  },
  methods: {
    unload() {
      const { map, originInstance } = this
      map['removeOverlay'](originInstance)
    },
    ready() {
      const $parent = getParent(this.$parent)
      const BMap = (this.BMap = $parent.BMap)
      const map = (this.map = $parent.map)
      this.load()
      this.$emit('ready', {
        BMap,
        map
      })
    },
    redraw() {
      this.originInstance.redraw()
    },
    bindEvent(instance) {
      const ev = ['close', 'open', 'maximize', 'restore', 'clickclose']
      ev &&
        ev.forEach((event) => {
          const hasOn = event.slice(0, 2) === 'on'
          const eventName = hasOn ? event.slice(2) : event
          const listener = this.$listeners[eventName]
          listener && instance.addEventListener(event, listener.fns)
        })
    },
    load() {
      console.log('MapInfoWindow-load')
      if (!this.originInstance) {
        const { BMap, map, title, width, $parent } = this
        const $content = this.$el
        const overlay = new BMap.InfoWindow($content, {
          width,
          title
        })
        this.bindEvent(overlay)
        this.originInstance = overlay
        this.$container = $parent.originInstance && $parent.originInstance.openInfoWindow ? $parent.originInstance : map
      }
    },
    // 打开弹窗，回调设置弹窗内容
    openInfoWindow() {
      const { $container, originInstance, BMap, lastPoint, objectInfo } = this
      if (lastPoint.lng === objectInfo.lng && lastPoint.lat === objectInfo.lat) {
      } else {
        const point = new BMap.Point(objectInfo.lng, objectInfo.lat)
        $container.openInfoWindow(originInstance, point)
      }
      // 设置弹窗显示数据
      this.infoWindowComponent && this.infoWindowComponent.setFomrData(this.objectInfo)
    },
    // 关闭弹窗
    closeInfoWindow() {
      this.$container.closeInfoWindow(this.originInstance)
    }
  }
}
</script>
